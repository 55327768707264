/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/14
* @description
*   TheCCList.vue of WeTrade
*/
<template>
  <FullScreen class="px-16 df-col">
    <PageHeader>
      <template #title>Country Codes</template>
    </PageHeader>
    <div class="flex-1 scroll-y mb-16">
      <div v-for="item in list" :key="item.code" class="option df-middle" :class="{active: value === item.code}"
        @click="select(item.code)">
        <p class="flex-1">
          +{{item.code}} {{item.name}}
        </p>
        <Icon v-if="value === item.code" name="check" class="check" sm />
      </div>
    </div>
  </FullScreen>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCCList',
  components: { Icon, PageHeader, FullScreen },
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: String,
  },
  setup (props, ctx) {
    return {
      select (code: string) {
        ctx.emit('select', code)
        ctx.emit('close')
      },
    }
  },
})
</script>

<style scoped lang="scss">
.option {
  height: 40px;
  margin-top: 8px;
  padding: 0 24px;
  border-radius: 2px;
  border: 1px solid transparent;
  background: var(--color-light);

  &.active {
    background: #E6EDFE;
  }
}

.check {
  border-radius: 8px;
  color: var(--color-white);
  background: var(--color-primary);
}
</style>
