/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   TheMobileInput.vue of WeTrade
*/
<template>
  <t path="signinup_16" #="{td}" as="div" custom>
    <FormInput name="mobile" :placeholder="td || 'Mobile Number'" v-bind="$attrs">
      <template #prefix>
        <HoldOn :content="selectedCC" style="min-width: 50px">
          <FormSelect :default-value="selectedCC" name="countryCode" #="{update}" class="cc">
            <span class="d-ib" @click="selectCC(update)">+{{selectedCC}}</span>
            <Icon name="caret-down" sm class="ml-4" />
          </FormSelect>
        </HoldOn>
      </template>
      <template #addon="{ update, value }">
        <Icon v-if="value" class="close-round" name="close" @click="update('')" />
      </template>
    </FormInput>
  </t>
</template>

<script lang="ts">
import * as R from 'ramda'
import Icon from '@/components/Icon.vue'
import { openFullscreenList } from '@/components/popup/popup'
import { CC, readCCList } from '@/modules/app/app.api'
import TheCCList from '@/pages/login/components/TheCCList.vue'
import HoldOn from '@/provider/HoldOn.vue'
import { YesOrNo } from '@/types'
import FormInput from 'common/form/inputs/FormInput.vue'
import FormSelect from 'common/form/inputs/FormSelect.vue'
import { defineComponent, shallowRef } from 'vue'
import state from '@/state'

export default defineComponent({
  name: 'TheMobileInput',
  components: { HoldOn, FormSelect, Icon, FormInput },
  props: {
    ccRequest: Function,
  },
  emits: ['ccChange'],
  setup (props, ctx) {
    const selectedCC = shallowRef('')
    const ccList = shallowRef<Array<CC>>([])

    const selectCC = (done: (code: string) => void) => {
      openFullscreenList(TheCCList, {
        list: ccList.value,
        value: selectedCC.value,
        onSelect (code: string) {
          selectedCC.value = code
          done(code)
          ctx.emit('ccChange', code)
          state.countryCode = code
        },
      })
    }

    const updateCCList = (resp: CC[]) => {
      ccList.value = resp
      const one = R.find(R.propEq('default', YesOrNo.YES), resp)
      selectedCC.value = (one as CC)?.code
      ctx.emit('ccChange', (one as CC)?.code)
      state.countryCode = (one as CC)?.code
    }

    if (typeof props.ccRequest === 'function') {
      props.ccRequest().then(updateCCList)
    } else {
      readCCList().then(updateCCList)
    }

    return {
      ccList,
      selectedCC,
      selectCC,
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/_index.scss" as mixins;

.cc {
  padding: 2px 4px;
  margin-right: 4px;
  @include mixins.half-pixel-border(right);

  &::after {
    left: auto;
  }
}

.close-round {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: var(--color-tip);
  color: var(--color-white);
}
</style>
