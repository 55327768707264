/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/14
* @description
*   FormSelect.vue of WeTrade
*/
<template>
  <FormItem #="{ update }">
    <slot :update="update" />
  </FormItem>
</template>

<script lang="ts">
import FormItem from 'common/form/FormItem.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormSelect',
  components: { FormItem },
})
</script>

<style scoped lang="scss">
</style>
