/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   TheCodeInput.vue of WeTrade
*/
<template>
  <t path="signinup_5" #="{td}" as="div" custom>
    <Code name="code" :placeholder="td || 'OTP'" v-bind="$attrs">
      <template #addon="{ cd }">
        <t class="c-primary f-medium" @click="send(cd)" path="signinup_6">Send</t>
      </template>
    </Code>
  </t>
</template>

<script lang="ts">
import Code from 'common/form/inputs/Code.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCodeInput',
  components: { Code },
  emits: ['send'],
  setup (props, ctx) {
    return {
      send (cd: () => void) {
        ctx.emit('send', cd)
      },
    }
  },
})
</script>
