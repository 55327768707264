
import Icon from '@/components/Icon.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCCList',
  components: { Icon, PageHeader, FullScreen },
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: String,
  },
  setup (props, ctx) {
    return {
      select (code: string) {
        ctx.emit('select', code)
        ctx.emit('close')
      },
    }
  },
})
